<template>
    <div class="main-wrapper">
        <el-scrollbar class="table-content">
            <el-table :data="promotionList"
                      v-if="promotionList.length == 0 && softList.length == 0 && liveList.length == 0"
                      class="customTable"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                <el-table-column label="学生提交答案" align="left">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="primary" v-if="scope.row.exam_content =='软文推广' || scope.row.exam_content == '电商平台活动实施'" :underline="false"
                                     @click="viewArticle(scope.row)"
                                     class="view-detail">查看详情
                            </el-link>
                            <el-link type="primary" v-if="scope.row.exam_content =='视频推广'" :underline="false"
                                     @click="downloadFile(scope.row)"
                                     class="view-detail">下载素材
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="promotionList" v-if="promotionList.length > 0" class="customTable"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                <el-table-column label="学生提交答案" align="center">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="primary" v-if="scope.row.exam_content =='软文推广' || scope.row.exam_content == '电商平台活动实施'" :underline="false"
                                     @click="viewArticle(scope.row)"
                                     class="view-detail">查看详情
                            </el-link>
                            <el-link type="primary" v-if="scope.row.exam_content =='视频推广'" :underline="false"
                                     @click="downloadFile(scope.row)"
                                     class="view-detail">下载素材
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="正确答案" align="center" v-if="type == null">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="viewScoreDetail(scope.$index)"
                                 class="view-detail">查看详情
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="score" :label="'得分（'+prompte_total+'分）'" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.score}}</span>
                    </template>
                </el-table-column>
            
            </el-table>
            <el-table :data="softList" v-if="softList.length > 0" class="customTable"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="content" label="考试内容">
                    <template slot-scope="scope">
                        <span v-if="scope.$index == 0">{{scope.row.exam_content}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="分析问题">
                    <template slot-scope="scope">
                        <span>{{scope.row.analysis_problem}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="学生提交答案" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.stu_answer}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="score" label="正确答案" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.answer}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="score" :label="'得分（'+soft_total+'分）'" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.score}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="liveList" v-if="liveList.length > 0" class="customTable"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="content" label="考试内容" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.$index == 0">{{scope.row.exam_content}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="point" label="考点" width="140">
                    <template slot-scope="scope">
                        <span>{{scope.row.exam_site}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="exam_content" label="填写内容" width="120">
                    <template slot-scope="scope">
                        <span>{{scope.row.content}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="学生提交答案" align="left" width="330">
                    <template slot-scope="scope">
                        <span class="show-text">{{scope.row.stu_answer}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="score" label="正确答案" align="left">
                    <template slot-scope="scope">
                        <span class="show-text">{{scope.row.answer}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="score" :label="'得分（'+live_total+'分）'" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.score}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>
        <el-dialog title="评分详情"
                   :visible.sync="scoreDetailDialog" width="830px" @close="handleClose"
                   custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="scoreList" class="customTable goods-text-table" style="width: 100%; flex: 1;"
                          height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column prop="nickname" label="评委名称" align="left"></el-table-column>
                    <el-table-column prop="score" label="分数" align="center"></el-table-column>
                    <el-table-column prop="update_time" label="评分时间" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.update_time}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination class="pages-center" :total="total" @current-change="handleCurrentChange"/>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import pagination from "../pagination";
    import {teacheropNetworkPromotionDetail, teacheropTeacherScoreDetail} from "@/utils/apis"

    export default {
        name: "NetworkPromote",
        components: {
            pagination
        },
        data() {
            return {
                examId: this.$route.query.examId,
                studentId: this.$route.query.id,
                type: this.$route.query.type || null,
                promotionList: [],
                scoreDetailDialog: false,
                scoreList: [],
                answerList: [],
                total: 0,
                currentPageNum: 1,
                softInfo: {},
                softList: [],
                liveList: [],
                liveInfo: {},
                prompte_total: 0,
                live_total: 0,
                soft_total: 0,
                softType: 3,
                exam_id:this.$route.query.examId,
                student_id: this.$route.query.id,
            }
        },
        created() {
            this.getList();
            console.log(this.$route.query.id,'999')
        },
        methods: {
            getList() {
                let param = {
                    exam_id: this.examId,
                    student_id: this.studentId
                }
                teacheropNetworkPromotionDetail(param).then(res => {
                    this.softInfo = res.data.promote;
                    this.liveInfo = res.data.live;
                    console.log('liveinfo', this.liveInfo);
                    console.log('softInfo', this.softInfo);
                    if (res.data.user_analysis) {
                        this.softList = res.data.user_analysis.data
                    }
                    this.promotionList = this.softInfo ? this.softInfo.data : []
                    this.liveList = this.liveInfo ? this.liveInfo.data : []
                    this.formatTotal();
                }).catch(err => {
                    console.error(err)
                })
            },
            formatTotal() {
                this.promotionList.forEach(item => {
                    this.prompte_total += Number(item.score)
                })
                this.softList.forEach(item => {
                    this.soft_total += Number(item.score)
                })
                this.liveList.forEach(item => {
                    this.live_total += Number(item.score)
                })
            },
            getScore() {
                console.log(localStorage.getItem('role') == 6)
                let param = {
                    exam_id: this.$route.query.examId,
                    student_id: this.$route.query.id,
                    type: this.softType,
                    page: this.currentPageNum,
                    limit: 10
                }
                teacheropTeacherScoreDetail(param).then(res => {
                    this.scoreList = res.data.list.map(item => {
                        let date = new Date();
                        date.setTime(item.update_time * 1000);
                        let Y = date.getFullYear() + '-';
                        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                        let D = date.getDate() + ' ';
                        let h = date.getHours() + ':';
                        let m = date.getMinutes() + ':';
                        let s = date.getSeconds();
                        item.update_time = Y + M + D + h + m + s
                        return item
                    });
                    this.total = res.data.total
                    this.scoreDetailDialog = true
                }).catch(err => {
                    console.error(err)
                })
                 

            },
            viewArticle(row) {
                if (localStorage.getItem('role') == 6){
                      this.$router.push({
                        path: '/evaluation/articleDetail',
                        name: 'examManageExamarticleDetail',
                        query: {
                            article_id: row.id
                        }
                    })
                 }
                  if (localStorage.getItem('role') == 4){
                      this.$router.push({
                        path: '/judges/articleDetail',
                        name: 'judgesArticleDetail',
                        query: {
                            article_id: row.id
                        }
                    })
                 }
              
            },
            //下载文件
            downloadFile(row) {
                window.open(row.download_path, '_blank')
            },
            viewScoreDetail(index) {
                console.log(index)
                if (index == 0) {
                    this.softType = 3;
                } else {
                    this.softType = 4;
                }
                // this.getScore();
                if (localStorage.getItem('role') == 6){
                      console.log(this.exam_id,'9996')
                      this.$router.push({
                        path: '/evaluation/articleStandard',
                        name: 'examManageExamarticleStandard',
                        query: {
                            exam_id: this.exam_id,
                            student_id: this.exam_id,
                            // type: this.softType,
                            // page: this.currentPageNum,
                            // limit: 10
                        }
                    })
                 }
                    if (localStorage.getItem('role') == 4){
                      this.$router.push({
                        path: '/judges/articleStandard',
                        name: 'judgesArticleStandard',
                        query: {
                            exam_id: this.exam_id,
                            student_id: this.exam_id,
                            // type: this.softType,
                            // page: this.currentPageNum,
                            // limit: 10
                        }
                    })
                 }
            },
            handleClose() {
                this.scoreDetailDialog = false
            },
            //切换分页
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getScore();
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;

        .table-content {
            height: 100%;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .customTable {
                width: 100%;
                flex: 1;
                margin-top: 20px;

                .show-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .upload-file-content {
            height: 100%;

            .pages-center {
                margin-bottom: 0;
            }
        }
    }
</style>